import common from "@/common/common";
import store from "@/store";
let token = localStorage.getItem("businessToken") || "";
let Socket = "";
let setIntervalWesocketPush = null;
/**
 * 建立websocket连接
 * @param {string} url ws地址
 */
export const createSocket = () => {
    setTimeout(() => {
        if (!token) return;
        Socket && Socket.close();
        if (!Socket) {
            let userId = store.state.userInfo.userId || "";
            let url = `ws://${common.base_Url.slice(7)}${
                common.merSer
            }/webSocket?token=${token}&${userId}`;
            console.log("建立websocket连接");
            Socket = new WebSocket(url);
            Socket.onopen = onopenWS;
            Socket.onmessage = onmessageWS;
            Socket.onerror = onerrorWS;
            Socket.onclose = oncloseWS;
        } else {
            console.log("websocket已连接");
        }
    }, 200);
};

/**打开WS之后发送心跳 */
const onopenWS = () => {
    console.log("open");
    console.log("websocket已连接");

    // connecting(
    //   "22222[[[077951678c99f186a14c45487890179d<CT2024060313319;b78044a1a7e0cf79664a3675270dcd77,1"
    // );
    // sendPing(
    //   5000,
    //   "heart[[[077951678c99f186a14c45487890179d<CT2024060313319;b78044a1a7e0cf79664a3675270dcd77,1"
    // );
    sendPing();
};

/**连接失败重连 */
const onerrorWS = () => {
    console.log("error");

    Socket.close();
    clearInterval(setIntervalWesocketPush);
    console.log("连接失败重连中");
    if (Socket.readyState !== 3) {
        Socket = null;
        createSocket();
    }
};

/**WS数据接收统一处理 */
const onmessageWS = (e) => {
    console.log("onmessage");
    let time = new Date().getTime();
    let msg = e.data + ">>>" + time;
    store.state.socketData = msg;
    /*   window.dispatchEvent(
    new CustomEvent("onmessageWS", {
      detail: {
        data: e.data,
      },
    })
  ); */
};

/**
 * 发送数据但连接未建立时进行处理等待重发
 * @param {any} message 需要发送的数据
 */
const connecting = (message) => {
    setTimeout(() => {
        if (Socket.readyState === 0) {
            connecting(message);
        } else {
            Socket.send(message);
        }
    }, 1000);
};

/**
 * 发送数据
 * @param {any} message 需要发送的数据
 */
export const sendWSPush = (message) => {
    if (Socket !== null && Socket.readyState === 3) {
        Socket.close();
        createSocket();
    } else if (Socket.readyState === 1) {
        Socket.send(message);
    } else if (Socket.readyState === 0) {
        connecting(message);
    }
};

/**断开重连 */
const oncloseWS = () => {
    console.log("close");
    clearInterval(setIntervalWesocketPush);
    console.log("websocket已断开....正在尝试重连");
    if (Socket.readyState !== 2) {
        Socket = null;
        createSocket();
    }
};
/**发送心跳
 * @param {number} time 心跳间隔毫秒 默认5000
 * @param {string} ping 心跳名称 默认字符串ping
 */
export const sendPing = (time = 3000, ping = "ping") => {
    /* clearInterval(setIntervalWesocketPush);
  Socket.send(ping);
  setIntervalWesocketPush = setInterval(() => {
    Socket.send(ping);
  }, time); */

    clearInterval(setIntervalWesocketPush);
    setIntervalWesocketPush = setInterval(() => {
        if (Socket.readyState !== 1) {
            oncloseWS();
        }
    }, time);
};
